import React from 'react'

const ThreeSixty = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.0941 18.6751C10.8076 18.3129 11.5211 17.9672 12.1974 17.5475C12.9975 17.0495 13.7564 16.4898 14.474 15.8807C14.6307 15.7449 14.7091 15.7655 14.8369 15.8972C15.2204 16.2964 15.6164 16.6832 16.004 17.0742C16.037 17.1112 16.07 17.1483 16.1195 17.1442C16.1773 17.14 16.169 17.0907 16.1773 17.0536C16.3876 16.0906 16.5979 15.1235 16.8083 14.1605C16.9567 13.4814 17.1135 12.8065 17.2619 12.1274C17.3898 11.5471 17.5052 10.9669 17.6331 10.3907C17.6537 10.2961 17.6372 10.2631 17.5341 10.2878C17.3485 10.3331 17.1629 10.3742 16.9774 10.4195C16.4247 10.543 15.8762 10.6706 15.3236 10.7899C14.7379 10.9175 14.1523 11.0409 13.5666 11.1685C12.9934 11.292 12.4201 11.4155 11.851 11.543C11.4963 11.6212 11.1375 11.6994 10.7292 11.79C11.1334 12.185 11.5004 12.5431 11.8675 12.9052C12.0489 13.0822 12.0489 13.0904 11.8592 13.2427C10.8364 14.0658 9.75177 14.7942 8.55164 15.3292C7.66081 15.7284 6.74112 16.0124 5.74306 15.9424C5.29765 15.9095 4.88936 15.7655 4.55117 15.4651C3.9078 14.8971 3.85831 14.144 3.92842 13.3744C3.98203 12.8065 4.13463 12.2509 4.36146 11.7241C4.41507 11.6006 4.38208 11.5554 4.24186 11.5554C3.93254 11.5636 3.62323 11.5677 3.31392 11.5513L3.22318 11.5554C3.13657 11.5595 3.05822 11.6171 3.02522 11.6994C2.64992 12.6542 2.1179 14.1358 2.06429 15.1687C2.02717 15.8766 2.06016 16.5721 2.30349 17.247C2.6458 18.1936 3.09121 18.6422 3.70159 19.0085C4.31197 19.3747 5.12856 19.5352 5.12856 19.5352C5.12856 19.5352 7.36387 20.0579 10.0941 18.6751Z" fill="currentColor" />
            <path d="M17.2042 0.632983C16.3835 0.620636 15.5792 0.739985 14.7915 0.953988C14.0615 1.15153 13.3604 1.43549 12.6758 1.76062C11.6819 2.23389 10.7457 2.80594 9.85487 3.44795C9.79301 3.49322 9.68578 3.51791 9.6899 3.64138C10.3003 3.64138 10.9065 3.63726 11.5087 3.64549C11.6654 3.64549 11.8015 3.61668 11.9335 3.53437C12.2057 3.36153 12.4985 3.23395 12.7954 3.11048C13.7481 2.70717 14.7132 2.37382 15.7731 2.54667C16.4123 2.64955 16.899 2.97879 17.1794 3.57141C17.4722 4.18873 17.431 4.83897 17.3114 5.48098C17.1258 6.46869 16.7051 7.36997 16.2144 8.23833C16.0948 8.44822 15.9752 8.65399 15.8391 8.89269C16.07 8.84742 16.2639 8.8145 16.4536 8.76923C17.2042 8.60049 17.9589 8.43176 18.7095 8.26303C19.2498 8.14368 19.79 8.02845 20.3303 7.91733C20.4334 7.89675 20.4788 7.83091 20.5118 7.74448C20.7056 7.19713 20.8706 6.64566 20.9531 6.07361C21.0273 5.55506 20.9943 5.0324 20.9819 4.51385C20.9448 2.65778 19.6374 1.10214 17.8228 0.69883C17.8187 0.694715 17.4063 0.632983 17.2042 0.632983Z" fill="currentColor" />
            <path d="M0.130038 5.41102C0.0929204 5.1929 0.0599266 5.06944 0.187776 5.02005C0.987869 4.70316 1.80446 4.55501 2.64167 4.85543C3.16957 5.04475 3.51187 5.42337 3.54899 6.00776C3.58198 6.54277 3.33041 6.93785 2.8685 7.19712C2.79426 7.23828 2.72003 7.28766 2.63342 7.33705C2.74065 7.43582 2.8685 7.44405 2.97573 7.49344C3.8913 7.93379 4.04389 9.13962 3.25205 9.81455C2.67054 10.3125 1.96118 10.4113 1.22707 10.3619C0.839398 10.3372 0.459973 10.2549 0.0970444 10.1109C0.0310574 10.0862 -0.0101846 10.0162 0.00218795 9.94625L0.113541 9.19312C0.158907 9.07377 0.200149 9.05319 0.315627 9.10669C0.765163 9.30423 1.22707 9.42358 1.73022 9.36597C2.24162 9.30835 2.54681 8.91327 2.42721 8.44411C2.3571 8.16014 1.99005 7.90498 1.60237 7.87617C1.39616 7.85971 1.18995 7.84737 0.987869 7.84737C0.855895 7.84737 0.806405 7.79798 0.822902 7.64571C0.839399 7.47286 0.835274 7.2959 0.822902 7.11893C0.814653 6.98724 0.860019 6.94608 0.979621 6.9502C1.2312 6.95431 1.4869 6.97078 1.73435 6.8967C2.11377 6.78147 2.30761 6.58804 2.26637 6.21353C2.24575 6.0448 2.16326 5.9378 2.02716 5.85549C1.68898 5.65383 1.32605 5.68264 0.975496 5.78141C0.818777 5.82257 0.657934 5.8596 0.513588 5.93368C0.468222 5.95838 0.414607 5.94603 0.381613 5.90899C0.295005 5.81433 0.17128 5.64149 0.130038 5.41102Z" fill="currentColor" />
            <path d="M12.9233 7.49344C12.9027 8.14368 12.8408 8.78569 12.5191 9.37008C12.1685 10.0162 11.6159 10.3496 10.8942 10.366C10.2013 10.3825 9.64455 10.0903 9.27749 9.49766C8.81146 8.7363 8.74547 7.89264 8.81971 7.02839C8.86095 6.53454 8.98468 6.06538 9.24037 5.63325C9.6363 4.96655 10.2343 4.67024 11.0014 4.70316C12.0902 4.74432 12.6016 5.54683 12.7995 6.41519C12.8779 6.76912 12.9398 7.12716 12.9233 7.49344ZM11.6324 7.41113C11.6159 7.12305 11.6159 6.91728 11.5953 6.71562C11.5747 6.50985 11.5087 6.31642 11.4386 6.12299C11.319 5.79787 10.9602 5.50568 10.6096 5.71145C10.4405 5.81022 10.3457 5.96249 10.2797 6.13946C10.0487 6.74854 10.057 7.38232 10.0941 8.02022C10.1106 8.34534 10.1353 8.67457 10.2962 8.97088C10.3951 9.15608 10.5106 9.33716 10.7498 9.37008C11.121 9.41947 11.3561 9.22604 11.4798 8.81038C11.6324 8.32064 11.6242 7.82267 11.6324 7.41113Z" fill="currentColor" />
            <path d="M5.49973 6.97489C5.74306 6.85966 5.95752 6.69504 6.22971 6.64154C7.23601 6.444 8.16808 6.97901 8.33717 8.05314C8.49389 9.06143 7.88764 9.95036 7.05042 10.2384C6.11836 10.5594 5.0337 10.259 4.52642 9.38243C4.33671 9.05731 4.24185 8.70338 4.20473 8.33711C4.13875 7.73214 4.21711 7.13951 4.44394 6.57158C4.65015 6.0448 4.98421 5.61268 5.46674 5.29579C6.12248 4.86778 6.85246 4.69493 7.63194 4.70728C7.74329 4.70728 7.79278 4.73609 7.78865 4.85544C7.78041 5.02005 7.7969 5.18467 7.79278 5.34929C7.78041 5.72791 7.83815 5.6703 7.46284 5.68676C7.00918 5.70733 6.56377 5.77318 6.17197 6.02422C5.81317 6.25469 5.55334 6.54277 5.49973 6.97489ZM7.06692 8.46057C7.06692 8.37826 7.07105 8.29595 7.06692 8.21364C7.03805 7.81444 6.6875 7.48932 6.27095 7.48109C5.87091 7.47286 5.50385 7.77329 5.44612 8.16014C5.40075 8.4688 5.47086 8.75688 5.61108 9.0285C5.75543 9.30835 6.00288 9.43593 6.31632 9.43593C6.61738 9.43593 6.81947 9.27543 6.95969 9.01204C7.05455 8.83919 7.07517 8.654 7.06692 8.46057Z" fill="currentColor" />
            <path d="M14.709 6.94608C13.9708 6.98312 13.4223 6.35757 13.4305 5.71968C13.4347 5.0324 14.0038 4.49328 14.7297 4.49739C15.505 4.50151 15.9958 5.04886 16.004 5.71145C16.0123 6.36992 15.472 6.98724 14.709 6.94608ZM15.3277 5.7238C15.3277 5.33694 15.0926 5.09825 14.709 5.09413C14.3626 5.09413 14.1193 5.36164 14.1193 5.73614C14.1234 6.11476 14.3915 6.3905 14.7585 6.38638C15.105 6.38227 15.3277 6.11888 15.3277 5.7238Z" fill="currentColor" />
        </svg>

    )
}

export default ThreeSixty