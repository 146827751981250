
import { convertNumber, parseLanguage } from "../../utils/Helper"
import { PropertyType } from "../../utils/Types"
import LegacyCarousel from "../LegacyCarousel"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import carouselClasses from './listings/HouseCard.module.css'
import ThreeSixty from "../icons/ThreeSixty"
import Img from "../Img"


declare global {
    interface Window {
        dataLayer: any;
    }
}

interface IProps {
    lang: any
    electric_watt?: number
    property_type?: PropertyType
    photos: string[]
    is_wishlisted?: boolean
    is_sold?: boolean
    is_dollar?: boolean
    is_negotiable?: boolean
    is_sell?: boolean
    id?: string
    title: string
    bedroom?: number
    bathroom?: number
    price: string
    property_area?: number
    land_area?: number
    location: string
    direction?: string
    floor?: number | string
    furnish?: 'full' | 'semi' | 'non'
    view?: string
    containerClasses?: string
    priceDuration?: string
    url?: string
    contentPadding?: string
    sold_out?: boolean
    cluster_label: string
    cluster_category: string
    has_360?: boolean
}

const NewLaunchCard = ({
    lang, property_type, photos, id, electric_watt,
    is_wishlisted, is_sold, is_dollar, is_negotiable, is_sell,
    title, bedroom, bathroom, price, property_area, land_area, location,
    direction, floor, furnish, view,
    containerClasses, priceDuration, url,
    contentPadding, sold_out, cluster_label, cluster_category, has_360
}: IProps) => {
    const renderBedroom = property_type !== 'Ruko' ? bedroom === 0 ? 'Unit Studio' : bedroom === 1 ? `${bedroom} ${lang.bedroom_short}` : `${bedroom} ${lang.bedroom_short_multiple}` : `${bedroom} ${lang.storey}`
    const renderBathroom = bathroom === 1 ? `1 ${lang.bathroom_short}` : `${bathroom} ${lang.bathroom_short_multiple}`
    const renderFloor = () => {
        let floorArr = (!!floor && typeof floor === 'string') ? floor.replace(/ /g, '').split(',') : []
        let floorString = floor

        if (floorArr.length > 1) {
            floorString = `${floorArr[0]}-${floorArr[floorArr.length - 1]}`
        }

        return floorString
    }

    const renderPriceDuration = () => {
        switch (priceDuration) {
            case 'yearly':
                return lang.per_year
            case 'monthly':
                return lang.per_month
            case 'daily':
                return lang.per_day
            case 'none':
                return null
            default:
                return lang.per_month
        }
    }

    const leftArrow = (prev: () => void) => {
        return (
            <div className="hidden lg:block absolute left-0 z-1 transform top-1/2 -translate-y-1/2 ml-1">
                <button
                    onClick={prev}
                    className={`${carouselClasses.arrow} bg-white transform active:scale-90 shadow-lg relative h-10 w-10 rounded-full text-xl text-white outline-none focus:outline-none transition-all duration-150 linear`}
                >
                    <FontAwesomeIcon icon="angle-left" size="lg" width="0" className="absolute inset-0 mx-auto my-auto text-gray-77" />
                </button>
            </div>
        )
    }

    const rightArrow = (next: () => void) => {
        return (
            <div className="hidden lg:block absolute right-0 z-1 transform top-1/2 -translate-y-1/2 mr-1">
                <button
                    onClick={next}
                    className={`${carouselClasses.arrow} bg-white transform active:scale-90 shadow-lg relative h-10 w-10 rounded-full text-xl text-white outline-none focus:outline-none transition-all duration-150 linear`}
                >
                    <FontAwesomeIcon icon="angle-right" size="lg" width="0" className="absolute inset-0 mx-auto my-auto text-gray-77" />
                </button>
            </div>
        )
    }

    return (
        <div className={`relative bg-white flex flex-col h-full ${containerClasses || 'mx-3 mb-8 border border-gray-e6'}`}>
            {
                is_sold ?
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-50">
                        <Img src="/static/images/sold-out.png" alt="sold-out" className={`absolute left-0 ${carouselClasses['w-50']}`} />
                    </div>
                    : null
            }
            <div className="relative">
                {has_360 &&
                    <div className="absolute w-12 h-12 bg-off-white rounded-br-full z-1 flex">
                        <ThreeSixty width={21} height={20} fill="#E20A17" className="mt-3 ml-2 scale-110" />
                    </div>}
                {
                    sold_out &&
                    <div className={`${carouselClasses['sold-out']}`}>
                        <div className="border border-main text-white bg-main rounded-r-full py-1 px-4 text-xs">
                            <span>SOLD OUT</span>
                        </div>
                    </div>
                }
                {
                    photos.length === 0 ?
                        <a href={url} className="block">
                            <Img loading="lazy" width="360" height="240" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/default-thumbnail-unit.jpg`} alt="default" className="w-full" />
                        </a>
                        : photos.length === 1 ?
                            <a href={url} className="block">
                                <Img loading="lazy" width="360" height="240" src={photos[0]} alt="room" className="w-full" />
                            </a>
                            :
                            <LegacyCarousel
                                show={1}
                                withIndicator
                                indicatorContainerClasses={`${carouselClasses.dots} absolute bottom-0 mb-2 left-1/2 transform -translate-x-1/2`}
                                indicatorClasses={{
                                    active: carouselClasses.active,
                                    passive: carouselClasses.passive,
                                    far: carouselClasses.passive
                                }}
                                containerClasses={carouselClasses.container}
                                leftArrow={leftArrow}
                                rightArrow={rightArrow}
                            >
                                {
                                    photos.map((photo: string, idx) =>
                                        <a href={url} className="block w-full flex-shrink-0" key={idx} >
                                            <Img loading="lazy" width="360" height="240" src={photo} alt="room" className="w-full" />
                                        </a>
                                    )
                                }
                            </LegacyCarousel>
                }
            </div>
            <div className="flex flex-col h-full">
                <div className={`${contentPadding || 'px-3 md:px-6 pb-2 pt-4'} flex flex-col overflow-hidden`} style={{ height: 115 }}>
                    <a href={url}>
                        <h3 className="ellipsis-one-line font-bold text-left text-black-#3D3D3D focus:outline-none hover:text-blue-07 active:text-blue-07 cursor-pointer">{title}</h3>
                    </a>
                    <p className="text-gray-#8F8F8F mb-2 text-left font-semibold text-xs">
                        {location}
                    </p>
                    <div className="text-sm text-gray-70 mt-2 grid grid-cols-4">
                        {
                            property_type === 'Residential' ?
                                <>
                                    {
                                        bedroom ?
                                            <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_bedroom.svg" alt="bed" className={`mb-2`} />
                                                <span className="text-gray-#8F8F8F text-2xs tracking-tight">{renderBedroom}</span>
                                            </span>
                                            : null
                                    }
                                    {
                                        bathroom ?
                                            <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_bathtub.svg" alt="bed" className={`mb-2`} />
                                                <span className="text-gray-#8F8F8F text-2xs tracking-tight">{renderBathroom}</span>
                                            </span>
                                            : null
                                    }
                                    {
                                        electric_watt ?
                                            <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center flex-1 truncate">
                                                <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_electricity.svg" alt="bed" className={`mb-2`} />
                                                <span className="text-gray-#8F8F8F text-2xs tracking-tight truncate">{electric_watt} Watt</span>
                                            </span>
                                            : null
                                    }
                                    {
                                        direction ?
                                            <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center flex-1 truncate">
                                                <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_new_direction.svg" alt="bed" className={`mb-2`} />
                                                <span className="text-gray-#8F8F8F text-2xs tracking-tight truncate">{direction}</span>
                                            </span>
                                            : null
                                    }
                                </>
                                : property_type === 'Apartment' ?
                                    <>
                                        {
                                            furnish ?
                                                <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                    <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_furnish.svg" alt="bed" className={`mb-2`} />
                                                    <span className="text-gray-#8F8F8F text-2xs tracking-tight">{lang[furnish === 'full' ? furnish + 'y_furnished_short' : furnish + '_furnished_short']}</span>
                                                </span>
                                                : null
                                        }
                                        {
                                            floor ?
                                                <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                    <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_floor.svg" alt="bed" className={`mb-2`} />
                                                    <span className="text-gray-#8F8F8F text-2xs tracking-tight">{parseLanguage(lang.floor_value, ':floor', renderFloor() as string)}</span>
                                                </span>
                                                : null
                                        }
                                        {
                                            view ?
                                                <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center flex-1 truncate">
                                                    <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_view.svg" alt="bed" className={`mb-2`} />
                                                    <span className="text-gray-#8F8F8F text-2xs tracking-tight truncate">{view}</span>
                                                </span>
                                                : null
                                        }
                                    </>
                                    : property_type === 'Kavling' ?
                                        <>
                                            {
                                                direction ?
                                                    <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                        <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_new_direction.svg" alt="bed" className={`mb-2`} />
                                                        <span className="text-gray-#8F8F8F text-2xs tracking-tight">{direction}</span>
                                                    </span>
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                floor ?
                                                    <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                        <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_floor.svg" alt="bed" className={`mb-2`} />
                                                        <span className="text-gray-#8F8F8F text-2xs tracking-tight">{parseLanguage(lang.floor_value, ':floor', floor.toString())}</span>
                                                    </span>
                                                    : null
                                            }
                                            {
                                                direction ?
                                                    <span className="flex flex-col flex-shrink-0 flex-no-wrap items-center">
                                                        <img loading="lazy" width="18" height="18" src="/static/images/svg/icon_new_direction.svg" alt="bed" className={`mb-2`} />
                                                        <span className="text-gray-#8F8F8F text-2xs tracking-tight">{direction}</span>
                                                    </span>
                                                    : null
                                            }
                                        </>
                        }
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between flex-nowrap px-3 md:px-6 pt-2 pb-3 w-full border-t border-gray-e6">
                    <p className="text-2xs text-gray-#8F8F8F">{lang.start_from}</p>
                    <div className="flex flex-row items-end">
                        <p className="font-bold mr-1 text-black-#3D3D3D text-sm">{is_dollar ? '$' : 'Rp'} {convertNumber(price.toString())}</p>
                        {
                            !is_sell && renderPriceDuration() ?
                                <p className="text-star text-2xs font-semibold">{renderPriceDuration()}</p>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewLaunchCard
